import axios from "axios";
import store from "@/core/services/store";
import router from "@/router";
import toasted from "@/core/plugins/vue-toasted";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

function getHeaders() {
  let authToken = store.getters["auth/getAuthToken"];
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (authToken) headers["Authorization"] = authToken;

  return headers;
}

axios.interceptors.request.use(
  function (config) {
    // set Headers
    config.headers = getHeaders();
    store.commit("setLoadingStatus", true);
    return config;
  },
  function (error) {
    store.commit("setLoadingStatus", false);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    store.commit("setLoadingStatus", false);
    return response;
  },
  function (error) {
    store.commit("setLoadingStatus", false);
    let errorResponse = error.response;
    if (
      errorResponse.status === 401 &&
      router.history.current.name !== "login"
    ) {
      // clear user data
      localStorage.removeItem("authUser");
      store.commit("auth/setAuthUser", null);
      router.push({
        name: "login",
        query: {
          redirect: router.history.current.path,
        },
      });
    }
    if (errorResponse.status === 403) {
      /*  toasted.show(errorResponse?.data?.message, {
        type: "error",
        icon: "error",
      }); */
      /*       router.push({
        name: "404",
        params: { message: "This url is invalid" },
      }); */
    }
    if (errorResponse.status == 422) {
      toasted.show(errorResponse.data.errors?.[0]?.error, {
        type: "error",
        icon: "error",
      });
    }
    if (errorResponse.status === 500) {
      router.push({
        name: "404",
        params: { message: "Something Went Wrong! Try Again Later" },
      });
    }
    if (errorResponse?.data?.message) {
      toasted.show(errorResponse?.data?.message, {
        type: "error",
        icon: "error",
      });
    }
    return Promise.reject(error);
  }
);
