import axios from "axios";
import toasted from "@/core/plugins/vue-toasted";

const state = {
  talentsData: null,
  roles: [],
  industries: [],
  roleSkills: [],
  countries: [],
  regulationCountries: [],
};

const getters = {
  getTalentsData(state) {
    return state.talentsData?.data;
  },
  getTalentsMeta(state) {
    return state.talentsData?.meta;
  },
  getRoles(state) {
    return state.roles;
  },
  getIndusters(state) {
    return state.industries;
  },
  getRoleSkills(state) {
    return state.roleSkills;
  },
  getCountries(state) {
    return state.countries;
  },
  getRegulationCountries(state) {
    return state.regulationCountries;
  },
};

const mutations = {
  setTalentsData(state, payload) {
    state.talentsData = payload;
  },
  toggleContacted(state, payload) {
    let targetTalent = state.talentsData.data.find(
      (item) => item.id === payload
    );
    targetTalent.contacted = targetTalent.contacted ? 0 : 1;
  },
  setIndusters(state, payload) {
    state.industries = payload;
  },
  setRoles(state, payload) {
    state.roles = payload;
  },
  setRoleSkills(state, payload) {
    state.roleSkills = payload;
  },
  setCountries(state, payload) {
    state.countries = payload;
  },
  setRegulationCountries(state, payload) {
    state.regulationCountries = payload;
  },
};

const actions = {
  getTalentsData({ commit }, payload) {
    let { page = 1, perPage = 8 } = payload;
    page === 1 && commit("setTalentsData", null);
    return new Promise((resolve) => {
      axios
        .get("users/talents", {
          params: {
            page,
            perPage,
            ...payload,
          },
        })
        .then((res) => {
          commit("setTalentsData", res.data);
          resolve("done");
        });
    });
  },
  getIndustries({ commit }) {
    return new Promise((resolve) => {
      axios.get(`/staticContent/projectTypes`).then((res) => {
        commit("setIndusters", res.data.data);
        resolve(res.data.data);
      });
    });
  },
  toggleContacted({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("toggleContacted", payload);
      axios
        .put(`/users/${payload}/toggleContacted`)
        .then(() => {
          toasted.show("Operation Completed Successfully", {
            type: "success",
            icon: "check_circle",
          });
          resolve("done");
        })
        .catch(() => {
          commit("toggleContacted", payload);
          reject("done");
        });
    });
  },
  getRoles({ commit, getters }, sorted = false) {
    sorted;
    return new Promise((resolve) => {
      if (getters.getRoles.length === 0) {
        axios.get(`/staticContent/roles`).then((res) => {
          const data = res.data.map((item) => {
            if (item.group) return { header: item.group };
            else return item;
          });
          commit("setRoles", data);
          resolve("done");
        });
      } else {
        resolve("done");
      }
    });
  },
  getRoleSkills({ commit }, payload) {
    return new Promise((resolve) => {
      axios.get(`/staticContent/roles/${payload}/skills`).then((res) => {
        commit("setRoleSkills", res.data.data);
        resolve(res.data.data);
      });
    });
  },
  getAllSkills({ commit }) {
    return new Promise((resolve) => {
      axios.get(`/staticContent/skills/`).then((res) => {
        commit("setRoleSkills", res.data.data);
        resolve(res.data.data);
      });
    });
  },
  // delete talents
  // eslint-disable-next-line no-unused-vars
  deleteSelectedTalents({ commit }, payload) {
    let { users } = payload;
    return new Promise((resolve, reject) => {
      axios
        .delete(`/users/talents`, {
          data: {
            users,
          },
        })
        .then(() => {
          resolve("done");
        })
        .catch((error) => reject(error));
    });
  },
  getCountries({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/staticContent/populated/countries/talents`, {
          params: {
            ...payload,
          },
        })
        .then((res) => {
          commit("setCountries", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getRegulationCountries({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/staticContent/populated/countries/talents`)
        .then((res) => {
          commit("setRegulationCountries", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
