import axios from "axios";

const state = {
  talent_list: [],
  talent_Search_payload: {},
};

const getters = {
  getTalentList(state) {
    return state.talent_list;
  },
  getTalentSearchPayload(state) {
    return state.talent_Search_payload;
  },
};

const mutations = {
  setTalentList(state, payload) {
    state.talent_list = payload;
  },
  setTalentSearchPayload(state, payload) {
    state.talent_Search_payload = payload;
  },
};

const actions = {
  getTalentListRequest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/users/talents`, payload)
        .then((res) => {
          commit("setTalentList", res.data);
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },
  talentSearchPayload({ commit }, payload) {
    commit("setTalentSearchPayload", payload);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
