import axios from "axios";

const state = {
  authUser: JSON.parse(localStorage.getItem("authUser")) || null,
};

const getters = {
  getAuthUser(state) {
    return state.authUser?.user;
  },
  adminRole(state) {
    const roles = ["super-admin", "operation-team", "viewer"];
    return roles.some((role) =>
      state.authUser?.user.roles.map((r) => r.name).includes(role)
    );
  },
  isAuthUser(state) {
    // return state.authUser && Date.now() <= state.authUser.expires_at;
    return state.authUser;
  },
  getAuthToken(state, getters) {
    return (
      getters.isAuthUser &&
      `${state.authUser.token_type} ${state.authUser.access_token}`
    );
  },
};

const mutations = {
  setAuthUser(state, payload) {
    state.authUser = payload;
  },
};

const actions = {
  login({ commit }, payload) {
    return new Promise((resolve) => {
      axios.post("/auth/loginByPassword", payload).then((res) => {
        let response = res.data.data;
        response.expires_at = Date.now() + response.expires_in;
        commit("setAuthUser", response);
        localStorage.setItem("authUser", JSON.stringify(response));
        resolve("done");
      });
    });
  },
  logOut({ commit }) {
    return new Promise((resolve) => {
      axios.post("/auth/logout").then(() => {
        commit("setAuthUser", null);
        localStorage.removeItem("authUser");
        resolve("done");
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
