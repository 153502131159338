import axios from "axios";
// import toasted from "@/core/plugins/vue-toasted";

export default {
  namespaced: true,
  state: {
    contractsData: [],
    accountManagers: [],
    contractData: null,
  },
  getters: {
    getContractsData: (state) => state.contractsData?.data,
    getContractsMeta: (state) => state.contractsData?.meta,
    getAccountManagers: (state) => state.accountManagers,
    getContractData: (state) => state.contractData,
  },
  mutations: {
    setContractsData(state, data) {
      state.contractsData = data;
    },
    setAccountManagers(state, payload) {
      state.accountManagers = payload;
    },
    setContractData(state, data) {
      state.contractData = data;
    },
  },
  actions: {
    handleRaiseRequest(_, payload) {
      let { contract_id, action } = payload;
      return new Promise((resolve, reject) => {
        axios
          .patch(`/contracts/${contract_id}/raise`, {
            action,
          })
          .then(() => {
            resolve("done");
          })
          .catch((error) => reject(error));
      });
    },
    getContractRejectionReason(_, payload) {
      let { type } = payload;
      return new Promise((resolve) => {
        axios
          .get(`/staticContent/contractRejectionReason?type=${type}`)
          .then((res) => {
            resolve(res.data.data);
          });
      });
    },
    renewContract(_, payload) {
      let { contract_id, data } = payload;
      return new Promise((resolve, reject) => {
        axios
          .patch(`/contracts/${contract_id}`, {
            ...data,
          })
          .then(() => {
            resolve("done");
          })
          .catch((error) => reject(error));
      });
    },
    earlyRenewContract(_, payload) {
      let { contract_id } = payload;
      return new Promise((resolve, reject) => {
        axios
          .post(`/contracts/${contract_id}/renewal-terms`)
          .then(() => {
            resolve("done");
          })
          .catch((error) => reject(error));
      });
    },
    confirmRenewalState(_, payload) {
      let { contract_id } = payload;
      return new Promise((resolve, reject) => {
        axios
          .patch(`/contracts/${contract_id}/confirm`)
          .then(() => {
            resolve("done");
          })
          .catch((error) => reject(error));
      });
    },
    updateContract({ commit }, payload) {
      let { contractId, data } = payload;
      return new Promise((resolve, reject) => {
        axios
          .put(`/contracts/${contractId}/renewal-terms`, {
            ...data,
          })
          .then((res) => {
            commit("setContractData", res.data.data);
            resolve("done");
          })
          .catch((error) => reject(error));
      });
    },
    getContractsData({ commit }, payload) {
      let { page = 1, perPage = 8 } = payload;
      return new Promise((resolve) => {
        axios
          .get("/contracts", {
            params: {
              page,
              perPage,
              ...payload,
            },
          })
          .then((res) => {
            commit("setContractsData", res.data);
            resolve("done");
          });
      });
    },

    getAccountManagers({ commit }, scope = "contractAccountManager") {
      return new Promise((resolve) => {
        axios.get(`/admins?scope=${scope}`).then((res) => {
          commit("setAccountManagers", res.data.data);
          resolve(res.data.data);
        });
      });
    },
    getContractData({ commit }, payload) {
      let { contract_id } = payload;
      return new Promise((resolve) => {
        axios.get(`/contracts/${contract_id}`).then((res) => {
          commit("setContractData", res.data.data);
          resolve("done");
        });
      });
    },
  },
};
