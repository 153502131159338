import Vue from "vue";
import App from "./App.vue";
import "@/core/config/axios";
import router from "./router";
import store from "@/core/services/store";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// import Toasted from "vue-toasted";
// Vue.use(Toasted, {
//   position: "bottom-center",
//   duration: 5000,
// });

// window.$toasted = Vue.toasted;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "@/core/plugins/vue-toasted";

// router.beforeEach((to, from, next) => {
//   // Ensure we checked auth before each page load.
//   // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
//   next();
//   // reset config to initial state
//   store.dispatch(RESET_LAYOUT_CONFIG);

//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });

Vue.use(VueTelInputVuetify, {
  vuetify,
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
